<template>
    <div id="ConsolidationKey">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :tableName="tableName"
                :tableIcon="tableIcon"
                :tableDescription="tableDescription"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            >
                <!-- // Filters -->
                <template v-slot:filters>
                    <ZnapFilters ref="znapFilters"
                        :filterComponents="options"
                        :noData="noData"
                        @setSelection="populateFilterConditions"
                        :clearFilters="clearFilters"
                        @setClearFilters="setClearFilters"
                        @setDatepickerValue="setDatepickerValue"
                    >
                    </ZnapFilters>
                </template>
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'ConsolidationKey',

    components: { ZnapTable, ZnapFilters },

    computed: {
        endpoint() {
            return [
                this.$ipPlPlanning,
                'consolidation-key',
                {
                    conditions: [
                        // {
                        //     AndOr: 'AND',
                        //     column: 'id_module',
                        //     operator: '=',
                        //     value: 11
                        // }
                    ]
                }
            ]
        },
    },

    data: () => ({
        tableName: 'Chave de consolidação',
        tableIcon: 'mdi-table-plus',
        tableDescription: 'Forneça uma descrição para esta tabela.',
        tableRows: 10000,

        options: [
            {
                column: 'id_consolidation_key_type',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Tipo da chave de consolidação',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipPlPlanning, 'consolidation-key-type' ],
            },
            {
                column: 'posting_date',
                is: 'Datepicker',
                items: [],
                multiple: false,
                label: 'De/ Até',
                required: false,
                filterable: false,
                endpoint: [],
                value: null,
            },
            {
                column: 'id_cost_center',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Centro de custo',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_company_group',
                        operator: '=',
                        value: 1
                    }
                ]
            },
            {
                column: 'id_chart_account',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta contábil',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipOrganization, 'chart-account' ],
            },
            {
                column: 'created_by',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Usuário de criação',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUser, 'user' ],
            },
            {
                column: 'updated_by',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Usuário de atualização',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipUser, 'user' ],
            },
        ],

        noDataMessage: '',
        noData: true,

        // Filters
        filterConditions: [],
        loadTable: false,
        clearFilters: false,
    }),

    methods: {
        setDatepickerValue(e) {
            let option = this.options.find(o => o.column === e.column)
            option.value = e.value
        },

        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            let datepickerConditions = this.options.filter(o => o.is === 'Datepicker')
            if (datepickerConditions.length) {
                datepickerConditions.forEach(d => {
                    d.value = null
                })
            }
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>